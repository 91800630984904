import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "form"];

  connect(){
    this.countries = {
      "AD": { "length": 12, "example":"AD1200012030200359100100" },
      "AT": { "length": 20, "example":"AT611904300234573201" },
      "BE": { "length": 16, "example":"BE68539007547034" },
      "CY": { "length": 28, "example":"CY17002001280000001200527600" },
      "EE": { "length": 20, "example":"EE382200221020145685" },
      "FI": { "length": 18, "example":"FI2112345600000785" },
      "FR": { "length": 27, "example":"FR1420041010050500013M02606" },
      "DE": { "length": 22, "example":"DE89370400440532013000" },
      "GR": { "length": 27, "example":"GR1601101250000000012300695" },
      "IE": { "length": 22, "example":"IE29AIBK93115212345678" },
      "IT": { "length": 27, "example":"IT60X0542811101000000123456" },
      "LV": { "length": 21, "example":"LV80BANK0000435195001" },
      "LT": { "length": 20, "example":"LT121000011101001000" },
      "LU": { "length": 20, "example":"LU280019400644750000" },
      "MT": { "length": 31, "example":"MT84MALT011000012345MTLCAST001S" },
      "MC": { "length": 27, "example":"MC5811222000010123456789030" },
      "NL": { "length": 18, "example":"NL91ABNA0417164300" },
      "PT": { "length": 25, "example":"PT50000201231234567890154" },
      "SM": { "length": 27, "example":"SM86U0322509800000000270100" },
      "SK": { "length": 24, "example":"SK3112000000198742637541" },
      "SI": { "length": 19, "example":"SI56263300012039086" },
      "ES": { "length": 24, "example":"ES9121000418450200051332" },
      "VA": { "length": 22, "example":"VA59001123000012345678" },
    };
  }

  isValid() {
    const iban = this.inputTarget.value.replace(/\s/g, "");
    const countryCode = iban.slice(0, 2).toUpperCase();

    if (iban.length === 0) {
      return;
    }

    const isValidCountryCode = this.countries[countryCode] && iban.length === this.countries[countryCode].length;
    const isValidFormat = iban.slice(2).match(/^[a-zA-Z0-9]*/);

    if (isValidCountryCode && isValidFormat) {
      this.inputTarget.setCustomValidity("");
    } else {
      this.inputTarget.setCustomValidity("error");
      this.formTarget.classList.add("was-validated");
    }
  }
}
