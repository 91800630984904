// Company: TechBolic Solutions
// VERSION: 1.0.0
// Website: https://techbolic.com/

// import initializeAnimations from "../shared/animations";
// import initializeNavDropdowns from "../shared/nav_dropdowns";
// import initializePromoVideo from "../shared/promo_video";
import initializeScrollToTop from "../shared/scroll_to_top";
import initializeSliders from "../shared/sliders";
// import initializeActiveMenu from "../shared/menu";
import initializeLazyLoad from "../shared/lazyload";
import initializeFormWizard from "../shared/form_wizard";
import initializeFormValidations from "../shared/form_validations";
import initializeMaxLength from "../shared/max_length";
import initializePasswordValidation from "../shared/password_validation";
// import initializeCookiesConsent from "../shared/cookies_consent";
import initializeOnLoad from "../shared/on_load";
import initializeHeader from "../shared/header";
import initializeAccordion from "../shared/accordion";
import initializeBackgroundImage from "../shared/background_image";
// import initializeParallax from "../shared/parallax";
import initializeSmoothScroll from "../shared/smooth_scroll";
import initalizeToastNotifications from "../shared/toast_notifications";
import initializeFlashFade from "../shared/flash_fade";
// import initializeParallax from "../shared/parallax";
import initializeSideBar from "../shared/side_bar";
// import initializeDatatables from "../shared/datatables";
import displayModal from "../shared/display_modal";
import hideModal from "../shared/hide_modal";
import initializeLightbox from "../shared/lightbox";
import initializeDropdownMenu from "../shared/dropdown_menu";
import initializePaperProcessButtons from "../shared/process_modal";
import initializeWithdrawalConsentButtons from "../shared/withdrawal_consent_modal";
import initializeCopyAddressFields from "../shared/copy_address_field";
import redeemVoucher from "../shared/redeem_voucher";
import removeVoucher from "../shared/remove_voucher";
import initializePapersViewToggle from "../shared/papers_view_toggle";
import initializeTooltip from "../shared/tooltip";
import initializeClipboardCopy from "../shared/clipboard_copy";
// import initializeChargingStation from "../shared/charging_station";
import initializeShowPassword from "../shared/show_password";
import initializeEmailValidator from "../shared/email_validator";
import initializeEmailOkay from "../shared/email_okay";
import initializeSubmitOnEnter from "../shared/submit_on_enter";
import initializeSelect from "../shared/select";
import initializeProfilePaperDetailsTable from "../shared/profile_paper_details_table";
// import initializeThirdPartyPaperDatatable from "../shared/third_party_paper_datatable";
// import initializeChargingStationDatatable from "../shared/charging_station_datatable";
// import initializePaperDatatable from "../shared/user_paper_datatable";

// var initializedOnce = false;

window.initializePage = () => {
  // initializedOnce = true;
  if (!$("body").hasClass("loaded")) {
    initializeFormWizard();
    initializeSliders();
    initializeFormValidations();
    initializeMaxLength();
    initializePasswordValidation();
    // initializeCookiesConsent();
    initializeOnLoad();
    initializeAccordion();
    initializeBackgroundImage();
    initializeHeader();
    initializeSmoothScroll();
    // initializeAnimations();
    initalizeToastNotifications();
    initializeScrollToTop();
    initializeFlashFade();
    initializeSideBar();
    initializeLazyLoad();
    // initializeDatatables();
    initializeLightbox();
    initializeDropdownMenu();
    initializeCopyAddressFields();
    initializeTooltip();
    // initializeParallax();
    initializeClipboardCopy();
    // initializeChargingStation();
    initializeShowPassword();
    initializeEmailOkay();
    initializeSubmitOnEnter();
    initializeSelect();
    initializeProfilePaperDetailsTable();
  }
};

window.initFormValidations = initializeFormValidations;
window.initFormWizard = initializeFormWizard;
window.displayModal = displayModal;
window.hideModal = hideModal;
window.initializePaperProcessButtons = initializePaperProcessButtons;
window.initializeWithdrawalConsentButtons = initializeWithdrawalConsentButtons;
window.redeemVoucher = redeemVoucher;
window.removeVoucher = removeVoucher;
window.initPapersViewToggle = initializePapersViewToggle;
window.initEmailValidator = initializeEmailValidator;
// window.initThirdPartyPaperDatatable = initializeThirdPartyPaperDatatable;
// window.initChargingStationDatatable = initializeChargingStationDatatable;
// window.initPaperDatatable = initializePaperDatatable;

window.importDatatables = () => {
  import(/* webpackChunkName: "datatables" */ "../shared/datatables").then(
    (module) => {
      module.default();
    }
  );
};
document.removeEventListener("turbolinks:load", initializePage);
document.addEventListener("turbolinks:load", initializePage);

// function initializePageOnConsentLoad() {
//   if (!initializedOnce) {
//     intializePage();
//   }
// }

// window.removeEventListener('CookiebotOnLoad', initializePageOnConsentLoad);
// window.addEventListener('CookiebotOnLoad', initializePageOnConsentLoad);